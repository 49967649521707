import React, { useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import useAxiosInstance from "../../../Utils/Headers";
import { toast } from "react-toastify";

interface DetailsProps {
  data: any;
}

const Details: React.FC<DetailsProps> = ({ data }) => {
  const axiosInstance = useAxiosInstance();
  const [showCopymobileMessage, setShowCopymobileMessage] = useState(false);
  const [showCopyEmailMessage, setShowCopyEmailMessage] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [email, setEmail] = useState(data.email);
  const [mobileNumber, setMobileNumber] = useState(data.mobile_number);
  const [status, setStatus] = useState(data.status);

  // Refs for the input fields
  const emailInputRef = useRef<HTMLInputElement>(null);
  const mobileInputRef = useRef<HTMLInputElement>(null);
  const statusInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data) {
      setEmail(data.email);
      setMobileNumber(data.mobile_number);
      setStatus(data.status);
    }
  }, [data]);

  const copyMobileNumber = () => {
    if (mobileNumber) {
      navigator.clipboard
        .writeText(mobileNumber)
        .then(() => {
          setShowCopymobileMessage(true);
          setTimeout(() => setShowCopymobileMessage(false), 2000);
        })
        .catch((error) => {
          console.error("Failed to copy mobile number: ", error);
        });
    }
  };

  const copyEmail = () => {
    if (email) {
      navigator.clipboard
        .writeText(email)
        .then(() => {
          setShowCopyEmailMessage(true);
          setTimeout(() => setShowCopyEmailMessage(false), 2000);
        })
        .catch((error) => {
          console.error("Failed to copy email: ", error);
        });
    }
  };

  const handleVisitorEdit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]+$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      emailInputRef.current?.focus();
      return;
    }

    if (!mobileRegex.test(mobileNumber) || mobileNumber.length < 10) {
      toast.error("Please enter a valid mobile number.");
      mobileInputRef.current?.focus();
      return;
    }

    if (!status.trim()) {
      toast.error("Status cannot be empty.");
      statusInputRef.current?.focus();
      return;
    }

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/visitors/${data.id}`;
      await axiosInstance.put(apiUrl, {
        email,
        mobile_number: mobileNumber,
        status,
      });
      setIsEditMode(false);
      toast.success("Visitor details updated successfully!");
    } catch (error) {
      console.error("Error handling visitor edit:", error);
      toast.error("Failed to update visitor details. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEmail(data.email);
    setMobileNumber(data.mobile_number);
    setStatus(data.status);
  };

  return (
    <Card className="mt-5">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h1 className="m-0">Details</h1>
        <div>
          {isEditMode ? (
            <div>
              <button
                className="btn back-btn btn-sm me-2"
                onClick={handleCancelEdit}
              >
                Cancel
              </button>
              <button
                className="btn edit-btn btn-sm"
                onClick={handleVisitorEdit}
              >
                Save
              </button>
            </div>
          ) : (
            <button
              className="btn edit-btn btn-sm border-0"
              onClick={() => setIsEditMode(!isEditMode)}
            >
              Edit Details
            </button>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="table-responsive">
          <table className="table">
            <tbody className="col-12">
              <tr>
                <td className="col-sm-2 text-secondary">Email:</td>
                <td className="col-sm-4">
                  {isEditMode ? (
                    <input
                      ref={emailInputRef} // Assign ref to input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  ) : (
                    <>
                      <strong>
                        {email}
                        <button
                          title="Copy email"
                          className="fs bg-transparent border-0 btn-sm fs-7"
                          onClick={copyEmail}
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </strong>
                    </>
                  )}
                  {showCopyEmailMessage && (
                    <div className="copy-message">Email copied!</div>
                  )}
                </td>
                <td className="col-sm-2 text-secondary">Mobile:</td>
                <td className="col-sm-4">
                  {isEditMode ? (
                    <input
                      ref={mobileInputRef} // Assign ref to input
                      type="text"
                      className="form-control"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  ) : (
                    <>
                      <strong>
                        {mobileNumber}
                        <button
                          title="Copy number"
                          className="fs bg-transparent border-0 btn-sm fs-7"
                          onClick={copyMobileNumber}
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </strong>
                    </>
                  )}
                  {showCopymobileMessage && (
                    <div className="copy-message">Mobile number copied!</div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-sm-2 text-secondary">Status:</td>
                <td className="col-sm-4">
                  {isEditMode ? (
                    <input
                      ref={statusInputRef} // Assign ref to input
                      type="text"
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                  ) : (
                    <strong>{status}</strong>
                  )}
                </td>
              </tr>
              <tr>
                <td className="col-sm-2 text-secondary">Organization id:</td>
                <td className="col-sm-4">
                  <strong>{data.organization_id}</strong>
                </td>
                <td className="col-sm-2 text-secondary">Tracking code:</td>
                <td className="col-sm-4">
                  <strong>{data.tracking_code}</strong>
                </td>
              </tr>
              <tr>
                <td className="col-sm-2 text-secondary">Vendor code:</td>
                <td className="col-sm-4">
                  <strong>{data.vendor_code}</strong>
                </td>
                <td className="col-sm-2 text-secondary">History padding:</td>
                <td className="col-sm-4">
                  <strong>{data.use_history_padding}</strong>
                </td>
              </tr>
              <tr>
                <td className="col-sm-2 text-secondary">Blocked:</td>
                <td className="col-sm-4">
                  <strong>{data.is_blocked ? "Yes" : "No"}</strong>
                </td>
                <td className="col-sm-2 text-secondary">Top window:</td>
                <td className="col-sm-4">
                  <strong>{data.is_in_top_window ? "Yes" : "No"}</strong>
                </td>
              </tr>
              <tr>
                <td className="col-sm-2 text-secondary">Created At:</td>
                <td className="col-sm-4">
                  <strong>
                    {moment(data.created_at).format("DD-MMM-YYYY")}
                  </strong>
                </td>
                <td className="col-sm-2 text-secondary">Updated At:</td>
                <td className="col-sm-4">
                  <strong>
                    {moment(data.updated_at).format("DD-MMM-YYYY")}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Details;
